<template>
  <base-section
    id="article"
    space="72"
  >
    <v-container class="py-0">
      <v-row justify="center">
        <v-col
          cols="12"
          md="6"
        >
          <news-card
            class="mb-4"
            prominent
            v-bind="article"
          />

          <!-- <news-share /> -->

          <!-- <news-author v-bind="author" /> -->

          <!-- <news-comments /> -->

          <!-- <news-comment-reply /> -->
        </v-col>

        <!-- <v-col
          class="hidden-sm-and-down"
          cols="3"
        > -->
        <!-- <news-search /> -->

        <!-- <news-categories /> -->

        <!-- <news-recent-articles /> -->

        <!-- <news-archives /> -->

        <!-- <news-tags /> -->
        <!-- </v-col> -->
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNews',

    components: {
      // NewsArchives: () => import('@/components/news/Archives'),
      // NewsRecentArticles: () => import('@/components/news/RecentArticles'),
      // NewsAuthor: () => import('@/components/news/Author'),
      NewsCard: () => import('@/components/news/Card'),
      // NewsCategories: () => import('@/components/news/Categories'),
      // NewsCommentReply: () => import('@/components/news/CommentReply'),
      // NewsComments: () => import('@/components/news/Comments'),
      // NewsSearch: () => import('@/components/news/Search'),
      // NewsShare: () => import('@/components/news/Share'),
      // NewsTags: () => import('@/components/news/Tags'),
    },

    data: () => ({
      author: {
        name: 'John Leider',
        blurb: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo, odio nostrum.',
        src: require('@/assets/author.png'),
      },
      article: {
        icon: 'mdi-play',
        date: 'Jan 15, 2021',
        // category: 'Research Discussions',
        // comments: 0,
        title: 'Overview of ScrumOnDemand',
        text: 'Microsoft’s Women in Cloud Digital Summit to be held on the 28 – 30 Jan 2021 will feature ScrumOnDemand.',
        html: `
          <p>Listen to an overview of ScrumOnDemand as presented at the summit.</p>
        `,
        src: require('@/assets/article-sod-wic.png'),
      },
    }),
  }
</script>
